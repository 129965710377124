import React from 'react';
import Content from '../views/ROI/Logbook';

export default ({location}) => <Content location={location} seo={{
  title: 'How much can you save by using a driving logbook?',
  lang: 'da-DK',
  description: 'Estimate your annual expenses with our ROI calculator. See how much you can save with OBI+ by eliminating manual trip-logging.',
  meta: [{
    name: 'keywords',
    content: 'driving logbook'
  }]
}}/>